import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #007 - Nick Babcock, Bearded Owl Brewing"}
    blogUrl={"https://joincrafted.com/007-nick-babcock-bearded-owl-brewing"}
    description={
      "Nick Babcock, Founder & Head Brewer of Bearded Owl Brewing (Peoria, IL USA) shares of Pilsner and launching his brewery."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/bearded_owl/95689432_1393252200871929_5241329765914247168_o.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Nick%20Babcock,%20Founder%20%26%20Head%20Brewer%20of%20Bearded%20Owl%20Brewing%20(Peoria,%20IL%20USA)%20shares%20of%20Pilsner%20and%20launching%20his%20brewery.%0A%0Ahttps%3A//joincrafted.com/007-nick-babcock-bearded-owl-brewing"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/007-nick-babcock-bearded-owl-brewing"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>November 9, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;007 🍻 - Nick Babcock, Bearded Owl Brewing</h1>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>
                                                  Some beer styles are splashy, newfangled productions. You know what I'm talking about - they have 5+ words in their name. Very Berry Chocolate Hazelnut
                                                  Ice Cream Stout Sour. Ok maybe that's a bit overboard, but not by much.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>These sound and look impressive but they lack a charming simplicity that is characteristic of more traditional, time-tested beer styles.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingBottom: '8px'}}>
                                                <p>
                                                  Furthermore, it is often the case that subtile, more delicate beers are harder to get right. Fancy doesn't mean better. Neither does new, even if I do
                                                  appreciate from time to time the gimicky beer styles that the kids are drinking nowadays.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Nick Babcock</strong>, Founder &amp; Head Brewer of <a href="https://www.beardedowlbrewing.com/" style={{color: '#6821ab', fontWeight: 700, textDecoration: 'underline'}} target="_blank" rel="noreferrer">
                                                  <strong>Bearded Owl Brewing</strong>
                                                </a> in Peoria, IL, USA knows what all goes into a good, clean brew. He also knows how to stay up on the industry's beer-style trends. You need both, it's about
                                                finding the balance.
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingTop: '16px', paddingBottom: '16px'}}>
                                                <p>
                                                  I'm especially happy to highlight Nick in Crafted because he's brewing in a place that is dear to me. My wife grew up just outside Peoria, IL - we lived
                                                  there with my in-laws for a bit after my son was born. Nick's brewery is playing a big part in revitalizing the downtown area of this fine midwest city.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>It's great to see a brewery bring new life to a district who's heyday was thought to have already come and gone.</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>Read on to learn more about Nick and what he's brewing at Bearded Owl. We'll cover:</p>
                                                <ul>
                                                  <li>Pros and cons of starting a brewery without production experience</li>
                                                  <li>Pilsner: an underrated beer style and a tip on brewing it</li>
                                                  <li>The one word that Bearded Owl is trying to live up to</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                        <tbody>
                                          <tr>
                                            <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                              <div className="text-block" style={{lineHeight: '150%'}}>
                                                <EmailFormInPage />
                                                <div style={{textAlign: 'center'}}>
                                                  <span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>How'd you get into brewing? Can you tell us about your journey to get where you are today at Bearded Owl?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I became interested in brewing at about the same time I became interested in craft beer. I wanted to understand how different styles were made and how
                                                  to impart unique flavors by adding or subtracting different&nbsp;ingredients. After 3 or 4 years brewing my my garage I really got into packaging and
                                                  naming my beers for fun. It was around this time that I realized I could potentially do it professionally.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Bearded Owl came from really focusing on the craft and honing some of my favorite recipes in addition to finding like-minded individuals who were
                                                  interested in trying to make the jump from garage to brewery.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  For me personally, I think having no prior experience in a production&nbsp;brewery really hurt. Having to figure out things like glycol to water ratio
                                                  for our chilling system, the draught system, and transitioning to much larger equipment was a lot of late nights reading and working with others. As the
                                                  head brewer for this new company we had just started, there was no one else there to show me the ropes. The best part is having gone through&nbsp;all of
                                                  that I had to be self sufficient. No one was going to fix anything for me so the knowledge I gained was invaluable.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/bearded_owl/82266627_1298496853680798_8007401718012706816_o.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                San Junipero NE Pale Ale &amp; their crisp Honey Lager, served at Bearded Owl
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324e', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/beardedowlbrewing
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What's the one beer that you are most proud of? What's the story behind it?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Tough question! There are several that I am partial to for different reasons. Some of my favorite beers to drink are our barrel aged offerings and our
                                                  farmhouse/sour series because they are long plays and you have to be very patient to see how they turn out.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I am proud of those beers, but if I had to pick just one I would say our <a href="https://untappd.com/b/bearded-owl-brewing-pilsner/3763250" style={{color: '#6821ab', textDecoration: 'underline'}} target="_blank" rel="noreferrer">
                                                    Pilsner
                                                  </a>. Most people think they are easy to make, but you can't hide flaws in a Pilsner. There is nothing better for me than a Pilsner executed perfectly.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Water is super important depending on the style of&nbsp;pilsner.&nbsp; For someone who is just starting out brewing the style, I'd advise to build your
                                                  water profile to match the specific&nbsp;German region of the style. It's pretty easy to find classic water profiles online these days. The ingredients
                                                  are extremely basic so it's all about the process with pilsners.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/bearded_owl/95689432_1393252200871929_5241329765914247168_o.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Check out the thirst-quenching potential on that Pils
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324e', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/beardedowlbrewing
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What's a beer style that doesn't get enough attention today? What's a beer style that gets too much?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Did I mention Pilsner? Really I think a lot of the classic styles don't get the praise they deserve. Some breweries are cranking out really good
                                                  traditional style beers but you wouldn't know it. On the other side of the coin, crazily adjuncted&nbsp;beers are really popular. I'm guilty of liking a
                                                  lot of these too but I think it's gotten to the point where the trend has surpassed the quality in some cases.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>
                                                  As a brewer, have you ever had a moment where, when you taste a beer for the first time you say "Wow, whoever made this knows what they're doing"? How
                                                  do you know when a beer is well-crafted?
                                                </strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Definitely. You get better at being able to find flaws in beers or if they are not balanced properly. Regardless of style, you can usually tell if a
                                                  beer was well made whether you like the beer or not. There are a few breweries out there that are really cranking out some amazing beers and when I have
                                                  one I really like I wish I had thought of it or wonder what they are doing process wise that makes the beer a cut above the rest.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Just about anything can go wrong with beer if you are not diligent. From off flavors to carbonation or filtering issues. There are a ton of off flavors
                                                  that can crop up for a variety&nbsp;of reasons.&nbsp;Some common ones are diacetyl and DMS. Diacetyl will produce a buttery/slippery character in the
                                                  beer and DMS will produce a corn like character - both are undesirable in most styles.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#3d405b', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#f3f3f7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>If you had one word to describe what you want Bearded Owl to be, what would be the word?</strong>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Respected. We put a lot of time, effort, and passion into making beer. We take great pride in putting out a high quality product. We know that not
                                                  everyone will like every beer, and that not every beer will be successful, but we want to be respected for caring about what we serve to our customers
                                                  and to have them understand that a lot of work went into the 16 ounces sitting in front of them.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/bearded_owl/104826185_1435030946694054_2748898141015516510_o.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Nick Babcock, on premise 🍺
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324e', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/beardedowlbrewing
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                        <tbody>
                                          <tr>
                                            <td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                              <div className="text-block" style={{lineHeight: '150%'}}>
                                                <EmailFormInPage />
                                                <div style={{textAlign: 'center'}}>
                                                  <span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>
                                                  Wanting to be <strong>respected for caring about what you serve to your customers</strong> is a noble ambition. I can respect that.
                                                </p>
                                                <p style={{paddingBottom: '8px'}}>
                                                  The more you understand and appreciate <em>the work that went into the 16 ounces in front of you</em>, the better that 16 ounces tastes. Beer is better
                                                  when you know its story.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>
                                                  When you appeciate the product, you are appreciating the people who made it. Craft beer is awesome because there's a crafsperson behind it. Let's seek
                                                  out and support the craftspeople who work hard to bring us quality beer.
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#f3f9f0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                Thank you <strong>Nick</strong> for sharing your time and story with us. And thank you for what you're doing in your city.
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{color: '#28324e', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Thanks for reading. You make Crafted worth it.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#f3f9f0', padding: '8px', borderRadius: '4px'}}>
                                                <div style={{padding: '8px'}}>
                                                  Our sponsorship partner for this week's newsletter is <strong>Yakima Valley Hops</strong>. They care about the craft. If you haven't heard yet, <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" style={{textDecoration: 'underline', color: '#6821ab'}} target="_blank" rel="noreferrer">
                                                    their new hop aromas kit changes the game.
                                                  </a> Hone your hop noses and refine the language of your beer.
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '-8px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit">
                                                <img src="/assets/images/yakima_valley_hops_kit_1000x500.jpeg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} alt="Yakima Valley Hops Kit" />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
